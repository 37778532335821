<template>
  <div class="relative mb-5 overflow-hidden">
    <div class="text-lg">
      <h3>
        <span
          v-if="pre"
          :class="[
            preColor ? preColor : 'text-purple-light',
            'block text-base font-semibold tracking-wide uppercase',
          ]"
        >
          {{ pre }}
        </span>
        <span
          :class="[
            color ? color : 'text-purple',
            'mt-2 block text-3xl leading-8 font-extrabold tracking-tight  sm:text-4xl',
          ]"
        >
          <slot></slot>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    pre: String,
    color: String,
    preColor: String,
  },
};
</script>