<template>
  <div
    :class="[
      scrolling ? 'opacity-90' : '',
      'fixed bg-white shadow-lg w-full z-10 top-0',
    ]"
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6" v-scroll="handleScroll">
      <div
        :class="[
          scrolling ? 'py-3' : 'py-6',
          'flex justify-between items-center lg:justify-start lg:space-x-10',
        ]"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link :to="{ name: 'Homepage' }">
            <img
              :class="[scrolling ? 'h-12' : 'h-24', 'w-auto']"
              src="/images/ktm-logo.jpg"
              alt=""
            />
          </router-link>
        </div>
        <div class="lg:hidden">
          <a class="text-purple-dark font-bold" href="tel:7054762229">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 20"
              fill="currentColor"
              class="h-5 w-5 float-left mr-2"
            >
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
              />
            </svg>
            (705) 476-2229
          </a>
        </div>
        <div class="mr-2 my-2 lg:hidden">
          <button
            type="button"
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
            @click="mobile = !mobile"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        <nav class="hidden lg:flex space-x-10">
          <span v-for="(item, i) in links" :key="i">
            <router-link
              :to="{ name: item.to }"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              {{ item.title }}
            </router-link>
          </span>

          <a class="text-purple-dark font-bold" href="tel:7054762229">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 20"
              fill="currentColor"
              class="h-5 w-5 float-left mr-2"
            >
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
              />
            </svg>
            (705) 476-2229
          </a>
        </nav>
      </div>
    </div>
    <!--
      Mobile menu, show/hide based on mobile menu state.
      
      Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
      -->
    <div
      class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right lg:hidden"
      v-if="mobile"
    >
      <div
        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
      >
        <div class="pt-5 pb-6 px-5">
          <div class="flex items-center justify-between">
            <div class="text-center">
              <img
                class="h-24 w-auto"
                src="/images/ktm-logo.jpg"
                alt="Workflow"
              />
            </div>
            <div class="mr-2">
              <!-- close icon  -->
              <button
                type="button"
                class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                @click="mobile = !mobile"
              >
                <span class="sr-only">Close menu</span>
                <!-- Heroicon name: outline/x -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-6">
            <nav class="grid gap-y-8" v-for="(item, i) in links" :key="i">
              <router-link
                :to="{ name: item.to }"
                class="my-1 p-3 flex items-center rounded-md hover:bg-gray-50"
              >
                <span class="ml-3 text-base font-medium text-gray-900">
                  {{ item.title }}
                </span>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobile: false,
      scrolling: false,
      links: [
        {
          to: "Homepage",
          title: "Midwifery",
        },
        {
          to: "About",
          title: "About Us",
        },
        {
          to: "Team",
          title: "Our Team",
        },
        {
          to: "Services",
          title: "Services",
        },
        {
          to: "Resources",
          title: "Resources",
        },
        {
          to: "Contact",
          title: "Contact Us",
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 200) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    },
  },
  watch: {
    $route() {
      this.mobile = false;
    },
  },
};
</script>