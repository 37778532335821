<template>
  <div :class="[bgcolor]">
    <div class="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 py-14">
      <div class="lg:relative">
        <div class="container mx-auto">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgcolor: String,
  },
};
</script>
      
