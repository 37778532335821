<template>
  <div>
    <!-- hero  -->
    <hp-hero />
    <!-- covid 19 address  -->
    <page-section bgcolor="bg-white">
      <section-heading pre="Update">COVID-19 Address</section-heading>
      <div class="lg:relative mx-auto">
        <div class="container mx-auto">
          <div v-if="!showMore" v-html="covid.slice(0, 1200) + '...'"></div>
          <div v-else v-html="covid"></div>
          <button
            v-if="!showMore"
            @click="showMore = !showMore"
            class="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md bg-yellow hover:text-white hover:bg-purple md:py-4 md:text-lg md:px-10"
          >
            Show More
          </button>
        </div>
      </div>
    </page-section>

    <!-- midwifery  -->
    <page-section bgcolor="bg-gray-50">
      <section-heading pre="K'TIGAANING">Midwifery</section-heading>
      <div class="grid">
        <div>
          <p>
            Ontario’s Midwives are highly skilled and qualified to provide safe
            and ethical care under the professional standards set by their
            governing body – the College of Midwives of Ontario (CMO). Midwives
            are required by the CMO to stay active in their practice and
            continue to improve their knowledge and skills.
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 text-center mb-5">
        <div v-for="(item, i) in gallery" :key="i">
          <img class="rounded-lg shadow-lg overflow-hidden" :src="item" />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 text-center mb-5">
        <div>
          <img
            src="/images/midwife5.jpg"
            class="w-full rounded-lg shadow-lg overflow-hidden"
          />
        </div>
      </div>
    </page-section>

    <!-- expectations  -->
    <page-section>
      <div
        class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start"
      >
        <div class="relative sm:py-16 lg:py-0">
          <!-- svg  -->
          <div
            aria-hidden="true"
            class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <div
              class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"
            ></div>
            <svg
              class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width="404"
              height="392"
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="392"
                fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
              />
            </svg>
          </div>
          <!-- Testimonial card-->
          <div
            class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20"
          >
            <div class="relative py-64 rounded-lg shadow-lg overflow-hidden">
              <img
                class="absolute inset-0 h-full w-full object-cover"
                src="/images/midwife6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0"
        >
          <!-- Content area -->
          <div class="pt-12 sm:pt-16 lg:pt-20">
            <h2
              class="text-3xl text-purple font-extrabold tracking-tight sm:text-4xl"
            >
              At K’Tigaaning Midwives you can expect:
            </h2>
            <div class="mt-6 text-gray-500 space-y-6">
              <ul
                class="divide-y divide-gray-200"
                v-for="(item, i) in expectations"
                :key="i"
              >
                <li class="flex md:py-0 md:pb-1">
                  <!-- Heroicon name: outline/check -->
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-green"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span class="ml-3 text-base text-gray-500">
                    {{ item }}
                  </span>
                </li>
              </ul>
              <p>
                K’Tigaaning Midwives support and participate in health
                promotion, education, cultural practices, and ceremonies, that
                deconstruct the systemic inequalities rooted in colonization.
              </p>
            </div>
          </div>
        </div>
      </div>
    </page-section>
  </div>
</template>

<script>
import SectionHeading from "@/components/SectionHeading";
import HpHero from "@/components/HpHero";
import PageSection from "@/components/PageSection";

export default {
  components: {
    SectionHeading,
    HpHero,
    PageSection,
  },
  data() {
    return {
      gallery: [
        "/images/midwife1.jpg",
        "/images/midwife2.jpg",
        "/images/midwife3.jpg",
        "/images/midwife4.jpg",
      ],
      expectations: [
        "Information about your choices and for those choices to be respected.",
        "The opportunity to personalize your birth plans",
        "Access to local resources for any additional prenatal and postpartum support you may need.",
        "A lower rate of C-sections and unnecessary interventions",
        "Midwives expertise includes vaginal birth after having had a previous C-section (VBAC)",
      ],
      showMore: false,
      covid: `
      <p>Ahnii and hello,</p>
          <p>
            Here at KTM, we are committed to providing our community with safe
            prenatal, intrapartum, and postpartum care. We understand that the
            Coronavirus/COVID-19 outbreak may be causing a lot of
            uncertainty/anxiety for our clients and their families. This
            situation and the parameters surrounding social isolation
            recommendations are rapidly evolving, but we felt it important to
            address a few important changes to the way care will be delivered
            during this time. If you are a current client, you may have already
            received a phone call from a midwife regarding your upcoming
            appointment, and if you haven’t yet, you should expect one soon.
            Please also feel free to call our office which is still (at this
            time) operating during regular business hours – please be mindful,
            this is subject to change.
          </p>
          <p class="text-red">
            Any visits conducted in person will require you to be screened for
            symptoms at the door before entering the clinic. For in-clinic
            appointments, you will be asked to put on a mask (you may bring your
            own if you have one clean that day) before coming into the building,
            use hand sanitizer, and then we will check your temperature. You
            will be asked to sanitize your phone (and keys if you are unable to
            leave them at the front or in a bag/pocket).
          </p>
          <p class="text-red">
            * if you have a temperature greater than 37.5 degrees, we will not
            be able to see you and we will plan to see you outside the clinic,
            at a later date *
          </p>
          <p class="text-red">
            We apologize for this level of intensity, we are all feeling it too.
          </p>
          <p class="text-red">
            However, to do our part during this current health crisis we must
            take the necessary precautions to protect you and your fellow
            community members.
          </p>
          <p>
            At this time, we are asking that all clients consider practicing
            social isolation as best they can to prevent spread of the virus.
            This means limiting visitors for yourself/your new baby. Monitor for
            signs and symptoms of the infection. Limit non-essential travel
            (including simple trips outside the house). Consider working from
            home if possible, and if not, employing safe precautions in your
            workspace.
          </p>
          <p>
            We also ask that clients who have any symptoms of COVID-19 (fever,
            fatigue, respiratory symptoms) consider canceling their prenatal
            appointment and self-isolating. You should contact the Health Unit,
            Telehealth, or Public Health for further direction with regards to
            testing. Please DO NOT take yourself to the ER unless your symptoms
            are severe and
            <strong
              >page your midwife for further direction if this is the
              case.</strong
            >
          </p>
          <p>
            If you are in labour or have urgent concerns, please
            <strong>page your midwife</strong> as you usually would.
          </p>
          <p>
            We will be rescheduling all “non-essential” in-clinic and home
            visits at this time. This may mean that if you and/or your
            newborn(s) are well and do not require any in-person assessments, we
            may either defer entirely or elect to conduct your visit over the
            phone or by some sort of teleconferencing (FaceTime, Skype, Zoom,
            etc.). This is so that you still have the opportunity to ask
            questions regarding your care. We will still be organizing all
            essential tests, ultrasounds, and prescriptions in the community for
            those who need them. If you are seeking a reference as to what is
            considered an “essential” visit, please see the World Health
            Organization recommendations for prenatal care for low-risk
            pregnancies (we will also post the link to our page). These
            recommendations do not necessarily apply to those who are considered
            to be experiencing a high-risk pregnancy.
          </p>
          <p>
            All clients meeting their midwives at the hospital will have to
            enter through the emergency room entrance. Please give yourself
            extra time to make your way to labor & delivery if you are going to
            the hospital, as you will have to be screened before entering.
          </p>
          <p>
            Please understand we must place a limit on the number of support
            people during labor and deliveries. So, our policy, as of right now,
            will allow only ONE support person at your labour/birth, who will
            also be subject to screening. North Bay Regional Health Centre has
            also employed this policy.
          </p>
          <p>
            We are still at this time offering out-of-hospital births, including
            clinic and home births, and are dedicated to ensuring this remains a
            healthy, sanitary option for our clients. We are also still offering
            postpartum home visits for new parents and babies who need them, but
            you may receive a phone call regarding a unique visit schedule.
          </p>
          <p>
            Please also note that as the situation evolves, our resources as a
            practice may become depleted. This may mean your care may be slower
            than usual or you may not see your “assigned” midwife – we ask that
            you please be respectful of these potentially unavoidable
            circumstances.
          </p>
          <p>
            Know that these measures are all in the interest of keeping you and
            your growing family as safe as possible. Please take good care of
            yourself and your loved ones. Burn sage, offer tobacco, but most of
            all, keep in touch with your daily routines (modified for safety
            reasons of course). Do not hesitate to reach out to our team for
            support. Take walks outside. The earth still has good medicine to
            offer.
          </p>
          <p>Miigwetch and thank you, for your understanding,</p>
          <p>Your Midwifery Team</p>
      `,
    };
  },
};
</script>
