<template>
  <div id="app">
    <nav-menu />
    <div class="mt-32">
      <transition name="slide" mode="out-in">
        <router-view />
      </transition>
    </div>

    <cta-footer v-if="showCtaFooter" />
    <map-footer />
    <nav-footer />
  </div>
</template>

<style>
.router-link-exact-active {
  color: #9b5d8c !important;
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30);
}

.bg-texture {
  background-repeat: repeat;
  background-image: url("@/images/texture.png");
}
</style>

<script>
import NavMenu from "@/components/NavMenu";
import NavFooter from "@/components/NavFooter";
import CtaFooter from "@/components/CtaFooter";
import MapFooter from "@/components/MapFooter";
import axios from 'axios';


export default {
  name: "home-view",
  components: { NavMenu, NavFooter, CtaFooter, MapFooter },
  methods: {
    async requestToken() {
      try {
        // Replace this with the appropriate data for your application
        const authData = {
          api_key: "1234567890abcdefg",
        };

        const response = await axios.post(
          "http://localhost:8000/token",
          authData
        );
        if (response.status === 200) {
          const token = response.data.access_token;
          localStorage.setItem("jwt_token", token);
        } else {
          console.error("Token request failed:", response);
        }
      } catch (error) {
        console.error("Token request failed:", error);
      }
    },
  },
  computed: {
    showCtaFooter() {
      return this.$route.name !== 'Contact';
    },

}};
</script>

