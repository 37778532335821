var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative mb-5 overflow-hidden"},[_c('div',{staticClass:"text-lg"},[_c('h3',[(_vm.pre)?_c('span',{class:[
          _vm.preColor ? _vm.preColor : 'text-purple-light',
          'block text-base font-semibold tracking-wide uppercase',
        ]},[_vm._v(" "+_vm._s(_vm.pre)+" ")]):_vm._e(),_c('span',{class:[
          _vm.color ? _vm.color : 'text-purple',
          'mt-2 block text-3xl leading-8 font-extrabold tracking-tight  sm:text-4xl',
        ]},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }