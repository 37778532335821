import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home-view.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Homepage",
    meta: {
      title: "Welcome to K'Tigaaning Midwives",
    },
    component: Home,
    
  },
  {
    path: "/team",
    name: "Team",
    meta: {
      title: 'Meet Our Team',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/team-view.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: 'About Us',
    },
    component: () =>
      import( "../views/about-view.vue"),
  },
  {
    path: "/services",
    name: "Services",
    meta: {
      title: 'Our Services',
    },
    component: () =>
      import( "../views/services-view.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    meta: {
      title: 'Resources',
    },
    component: () =>
      import( "../views/resources-view.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: 'Contact Us',
    },
    component: () =>
      import( "../views/contact-view.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // Update the document title with the title specified in the route meta property
  document.title = to.meta.title || '<%= htmlWebpackPlugin.options.title %>';

  // Continue the navigation
  next();
});

export default router;
